<template>
    <client-page>

        <sub-visual
        sh="국내사업"
        tabActive="연구개발"
        bg="/images/sub/visual/sv2.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <page-title
                tit="연구개발"
                sc="사각지대에 놓인 지역 주민들의 삶의 질 향상을 위해 지역사회 내 사회복지 시설의 신규 유치 및 운영을 지원하고, 최근 사회복지 동향에 대한 전문 정보의 공유를 통해 사회복지의 현대화·전문화에 기여함으로써 사회복지 활동 영역의 기반확대, 사회적 위상 제고" />

                <v-sheet class="mb-n40 mb-lg-n60">
                    <v-sheet v-for="item in info" :key="item.title" class="mb-40 mb-lg-60">
                        <v-row>
                            <v-col cols="12" md="5" class="mb-10 mb-md-0">
                                <v-img :src="item.image" max-width="486" :aspect-ratio="486 / 348" class="w-100 mx-auto"/>
                            </v-col>
                            <v-col cols="12" md="7">
                                <h4 class="tit tit--sm line-height-1">
                                    {{item.title}}
                                </h4> 
                                <v-divider class="my-12 my-lg-16" />
                                <p v-html="item.sc" class="page-text line-height-17"></p>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-sheet>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageTitle from "@/components/client/sub/page-title.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        PageTitle,
    },    
    data() {
        return {
            info : [
                {
                    title : "위드 캔 복지포럼",
                    sc : "사회복지 유관 종사자 및 일반 지역주민을 대상으로 사회복지계의 주요 현안을 주제로 위드 캔 복지포럼 개최. 상시적으로 발생하는 사회문제를 분석하여 그에 맞는 대응 및 예방 전략을 수립함으로써 실질적인 원조가 가능한 프로그램을 마련하고, 사회복지 동향을 파악하여 포럼화 함으로써 사회복지의 선구적 입지를 강화시킴.",
                    image : "/images/sub/business/development/development-img.jpg",
                },
                {
                    title : "연구지 및 자료집 발간",
                    sc : "사회복지 주제와 관련된 이론과 정책 및 제도 분석연구, 실증적이고 경험적인 조사연구, 실 천방법 개발과 효과성 연구 등을 게재 하며 서평을 통해 학술정보와 성찰적 사고를 교환하는 장으로 기능하고 있다. 또한 발간된 연구지 및 자료집은 지역주민들과 공유함으로써 접근성을 제고. 특히 특정 분야나 방법론에 국한하지 않고 사회복지학이라는 폭넓은 정체성을 유지하면서 점차 다변화, 세분화되어 가는 사회복지의 지류들을 하나로 응집해내어 사회복지학문 공동체와 사회복지현장의 발전에 기여",
                    image : "/images/sub/business/development/development-img2.jpg",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
</style>